body {
  overflow-x: hidden;
  height: 100%;
  position: relative;
  max-width: 100%;
  font-size: 0.875rem;
  /* background: #311898; */
  /* margin: 5rem; */
}
.tabcontent {
  display: none;
}

.register_bg {
  background-color: #311898;
  height: 100vh;
}
a {
  text-decoration: none;
}

.signin_card_body {
  background-color: #5f46c2;
  border-radius: 1rem;
}

.signin_card {
  border-radius: 1rem;
  background-color: #5f46c2;
  margin: 4rem 6rem;
}
.signup_card {
  border-radius: 1rem;
  background-color: #5f46c2;
  margin: 5.5rem 0rem;
}
