.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92vh;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: white;
  text-align: center;
}

.welcome-message {
  max-width: 650px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.welcome-message h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.welcome-message p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.explore-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #6e8efb;
  background: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.explore-button:hover {
  background: #f0f0f0;
  transform: scale(1.05);
}
