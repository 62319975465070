@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
body {
  background: linear-gradient(90deg, #efd5ff 0%, #efd5ff 100%);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.profile_head {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.profile_head img {
  width: 3rem;
  height: 3rem;
}
.profile_head span {
  font-size: medium;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 0 0 0.75rem;
}

@media (max-width: 991px) {
  .profile_head {
    justify-content: start;
  }
  .profile_head img {
    display: none;
  }
}

/* sidebar css */
.fixed-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.content {
  width: 100%; /* Adjust this value based on the width of your sidebar */
  overflow-y: auto;
  height: 90.5vh; /* Make sure it occupies full height */
}

.text-gradient {
  background: linear-gradient(
    to right,
    #e24e28,
    #f78b39
  ); /* Example gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav_background {
  background: linear-gradient(
    90deg,
    rgba(252, 245, 255, 1) 0%,
    rgba(223, 197, 255, 1) 100%
  );
}
/* .sidebar {
  width: 200px;
  float: left;
  margin-right: 20px;
}

.content {
  margin-left: 220px;
}

.list-group-item {
  cursor: pointer;
}

.sidebar {
  background-color: #f8f9fa;
  padding: 20px;
  width: 250px;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid #ddd;
}

.sidebar-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.list-group-item {
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.list-group-item:hover {
  background-color: #e9ecef;
}

.sidebar-icon {
  margin-right: 10px;
}

.sidebar-text {
  vertical-align: middle;
}

.content {
  margin-left: 270px; /* Adjusted to accommodate wider sidebar */
/* padding: 20px;
} */

/* drag and drop css */
.file-dropzone {
  border-radius: 5px;

  margin: 0 auto;
  text-align: center;
}

.dropzone {
  border: 2px dashed #cccccc;
  cursor: pointer;
  height: 4rem;
  background-color: #e6f1f8;
}

.files-list {
  margin-top: 20px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.file-item p {
  margin: 0;
}
