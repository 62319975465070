.profile_head {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile_head img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile_head span {
  margin-right: 10px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  width: 100%;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}
